body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header-call-info button {
  align-items: center!important;
  background: #00baf2!important;
  border: none!important;
  border-radius: 50px!important;
  color: #fff!important;
  display: inline-flex!important;
  font-size: 14px!important;
  font-weight: 700!important;
  gap: 10px!important;
  justify-content: center!important;
  line-height: 1!important;
  padding: 17px 35px!important;
  text-decoration: none!important;
  transition: all .25s ease-in-out!important;
}

.header-call-info button:hover{
  background: #022d62!important;
  color: #fff!important;
}